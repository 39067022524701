import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import PortableText from 'react-portable-text';
import loadable from '@loadable/component';

import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Hero } from '../components/General/Hero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { SEO } from '../components/SEO';
// import { Form } from '../components/ContactForm/Form';
import FormModalContext from '../context/FormModalContext';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	text: {
		color: '#4B5B69',
		fontFamily: 'Roboto',
		lineHeight: 1.8,
		'& a': {
			color: theme.workwaveBlue,
		},
	},
}));

const APITerms = ({ data, location }) => {
	const terms = data.terms.edges[0].node;
	const classes = useStyles();

	const { formModalOpen } = useContext(FormModalContext);

	const { hero, _rawPageContent, metaDescription, metaTitle } = terms;
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />

			<Hero hero={hero} />
			<Container style={{ marginBottom: '15rem' }}>
				<Typography className={classes.text}>
					<PortableText
						content={_rawPageContent}
						serializers={{
							h2: ({ children }) => (
								<Typography
									variant='h2'
									style={{ color: '#002D5C', lineHeight: 1.8 }}>
									{children}
								</Typography>
							),
							h4: ({ children }) => (
								<Typography
									variant='h5'
									style={{ color: '#002D5C', fontWeight: 700 }}>
									{children}
								</Typography>
							),
							normal: ({ children }) => (
								<Typography
									variant='body1'
									style={{
										fontWeight: 400,
										lineHeight: 1.8,
										marginTop: '18px',
										marginBottom: '18px',
									}}>
									{children}
								</Typography>
							),
							emailPhoneLink: (props) => {
								return <a href={props.emailPhoneHref}>{props.children[0]}</a>;
							},
						}}
					/>
				</Typography>
				{formModalOpen ? (
					<Form
						privacy
						noForm
						location={location}
						pardotUrl='https://go.workwave.com/l/1042281/2023-11-16/65swnn'
					/>
				) : null}
			</Container>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query APITermsPageQuery {
		terms: allSanityApiTerms {
			edges {
				node {
					metaTitle
					metaDescription
					hero {
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						_rawContent
					}
					_rawPageContent
				}
			}
		}
	}
`;

export default APITerms;
